import React from 'react'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import { graphql } from 'gatsby'

interface Props {
  data: any
  location: Location
}

const Page404: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  if (meta) {
    meta.title = '404 - Ada Aesthetics'
  }

  return (
    <Layout location={location}>
      <Meta site={meta} />
      <section className="ls s-py-60 s-py-lg-100 s-py-xl-200 mobile-over error-404 not-found page_404 container-px-lg-90">
        <div className="container-fluid">
          <div className="row text-center">
            <div className="col-12 ">
              <div className="content_404">
                <header className="page-header">
                  <h3>404</h3>
                  <p>Oops, page not found!</p>
                </header>

                <div className="page-content">
                  <p>
                    <a href="./" className="btn btn-outline-maincolor">
                      back to Homepage
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fw-divider-space hidden-below-lg mt-30"></div>
      </section>
    </Layout>
  )
}

export default Page404

export const pageQuery = graphql`
  query Page404Query {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
